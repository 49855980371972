import React, { Suspense, lazy, Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ChatProvider } from './customer-service/ChatProvider';

// Styles
import "./assets/scss/style-email.scss";
import "./assets/scss/dashlite.scss";

// Firebase initialization
import './firebase';

// Lazy-loaded components
const App = lazy(() => import("./App"));
const IndexTwo = lazy(() => import("./components/partials/default/IndexTwo"));

// Performance monitoring
import reportWebVitals from "./reportWebVitals";

// Initialize Stripe only if key is available
const stripePromise = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  : null;

/**
 * Fallback component for Suspense
 */
const LoadingFallback = () => <div>Loading...</div>;

/**
 * Error boundary component
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    // You can also log the error to an error reporting service here
  }

  render() {
    if (this.state.hasError) {
      return (
        <div role="alert">
          <h1>Something went wrong.</h1>
          <pre style={{ color: "red" }}>{this.state.error && this.state.error.toString()}</pre>
          <pre style={{ color: "red" }}>{this.state.errorInfo && this.state.errorInfo.componentStack}</pre>
        </div>
      );
    }

    return this.props.children;
  }
}

/**
 * Main application render function
 */
const renderApp = () => (
  <React.StrictMode>
    <ErrorBoundary>
      <Router basename="/">
        <AuthProvider>
          <ChatProvider>
            {stripePromise ? (
              <Elements stripe={stripePromise}>
                <Suspense fallback={<LoadingFallback />}>
                  <Switch>
                    <Route exact path="/indextwo" component={IndexTwo} />
                    <Route component={App} />
                  </Switch>
                </Suspense>
              </Elements>
            ) : (
              <Suspense fallback={<LoadingFallback />}>
                <Switch>
                  <Route exact path="/indextwo" component={IndexTwo} />
                  <Route component={App} />
                </Switch>
              </Suspense>
            )}
          </ChatProvider>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  </React.StrictMode>
);

// Render the application
ReactDOM.render(renderApp(), document.getElementById("root"));

// Report web vitals
reportWebVitals(console.log);