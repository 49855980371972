// src/components/chat/ChatProvider.js
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { 
  getFirestore, 
  collection, 
  addDoc, 
  query, 
  where, 
  onSnapshot,
  doc,
  updateDoc,
  arrayUnion,
  serverTimestamp 
} from 'firebase/firestore';
import axios from 'axios';

// Initial state
const initialState = {
  messages: [],
  activeChat: null,
  isLoading: false,
  error: null
};

// Create context
export const ChatContext = createContext({
  state: initialState,
  sendMessage: async () => {},
  startNewChat: async () => {},
  closeChat: () => {}
});

// Reducer to handle state updates
const chatReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_MESSAGES':
      return { 
        ...state, 
        messages: action.payload.messages 
      };
    case 'SET_LOADING':
      return { 
        ...state, 
        isLoading: action.payload 
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case 'SET_ACTIVE_CHAT':
      return {
        ...state,
        activeChat: action.payload
      };
    case 'ADD_MESSAGE':
      return {
        ...state,
        messages: [...state.messages, action.payload]
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

export const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, initialState);
  const db = getFirestore();

  // Listen to active chat updates
  useEffect(() => {
    let unsubscribe;

    if (state.activeChat) {
      const chatRef = doc(db, 'chats', state.activeChat);
      
      unsubscribe = onSnapshot(chatRef, (doc) => {
        if (doc.exists()) {
          const chatData = doc.data();
          dispatch({ 
            type: 'UPDATE_MESSAGES', 
            payload: { messages: chatData.messages } 
          });
        } else {
          dispatch({ 
            type: 'SET_ERROR', 
            payload: 'Chat not found' 
          });
        }
      }, (error) => {
        dispatch({ 
          type: 'SET_ERROR', 
          payload: error.message 
        });
      });
    }

    // Cleanup subscription on unmount
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [state.activeChat]);

  const startNewChat = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });

      // Create new chat document
      const chatRef = await addDoc(collection(db, 'chats'), {
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        messages: [{
          content: "Hi! How can I help you today?",
          sender: 'bot',
          timestamp: new Date().toISOString()
        }]
      });

      dispatch({ type: 'SET_ACTIVE_CHAT', payload: chatRef.id });
      
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error.message 
      });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const sendMessage = async (content) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });

      // If no active chat, start one
      if (!state.activeChat) {
        await startNewChat();
      }

      // Add message to Firebase
      const chatRef = doc(db, 'chats', state.activeChat);
      const newMessage = {
        content,
        sender: 'user',
        timestamp: new Date().toISOString()
      };

      await updateDoc(chatRef, {
        messages: arrayUnion(newMessage),
        updatedAt: serverTimestamp()
      });

      // Send to backend for AI processing
      const response = await axios.post('/api/chat/message', {
        chatId: state.activeChat,
        content,
        timestamp: new Date().toISOString()
      });

      // AI response will be added through the Firebase listener
      
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error.message 
      });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const closeChat = () => {
    dispatch({ type: 'RESET' });
  };

  return (
    <ChatContext.Provider 
      value={{
        state,
        sendMessage,
        startNewChat,
        closeChat
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

// Custom hook for using chat context
export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};

export default ChatProvider;