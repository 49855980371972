import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { getAuth, onAuthStateChanged, signOut, browserSessionPersistence, setPersistence } from "firebase/auth";
import { getApps } from 'firebase/app';

export const AuthContext = createContext();

const publicRoutes = ['/auth-login', '/auth-register', '/auth-reset', '/auth-success', '/landing', '/auths/terms', '/auths/faq'];

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const isPublicRoute = useCallback((path) => {
    return publicRoutes.some(route => path.startsWith(route));
  }, []);

  const isAuthenticated = useCallback(() => {
    const auth = getAuth();
    return !!auth.currentUser && !!auth.currentUser.uid;
  }, []);

  useEffect(() => {
    if (!getApps().length) {
      console.error("AuthProvider: Firebase app not initialized");
      setError(new Error("Firebase not initialized"));
      setLoading(false);
      return;
    }

    console.log("AuthProvider: Setting up auth state listener");
    const auth = getAuth();

    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        console.log("AuthProvider: Set persistence to browserSessionPersistence");
      })
      .catch((error) => {
        console.error("AuthProvider: Error setting persistence", error);
        setError(error);
      });

    const unsubscribe = onAuthStateChanged(auth, 
      (user) => {
        console.log("AuthProvider: Auth state changed", user ? `User logged in: ${user.uid}` : "User logged out");
        console.log("User object:", user);
        setUser(user);
        setLoading(false);
        setError(null);
      },
      (error) => {
        console.error("AuthProvider: Auth state change error", error);
        setError(error);
        setLoading(false);
      }
    );

    return () => {
      console.log("AuthProvider: Cleaning up auth state listener");
      unsubscribe();
    };
  }, []);

  const logout = useCallback(async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setUser(null);
      console.log("AuthProvider: User logged out successfully");
      localStorage.clear();
      sessionStorage.clear();
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach(name => {
            caches.delete(name);
          });
        });
      }
    } catch (error) {
      console.error("AuthProvider: Error during logout", error);
      setError(error);
    }
  }, []);

  const refreshToken = useCallback(async () => {
    const auth = getAuth();
    if (auth.currentUser) {
      try {
        await auth.currentUser.getIdToken(true);
        console.log("Token refreshed successfully");
      } catch (error) {
        console.error("Error refreshing token:", error);
        setError(error);
        await logout();
      }
    }
  }, [logout]);

  const value = useMemo(() => {
    const authState = isAuthenticated();
    console.log("AuthProvider: Authentication state updated", { isAuthenticated: authState, loading, userEmail: user?.email });
    return {
      user,
      setUser,
      isAuthenticated: authState,
      isPublicRoute,
      loading,
      error,
      logout,
      refreshToken
    };
  }, [user, loading, error, isAuthenticated, isPublicRoute, logout, refreshToken]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Utility function for making authenticated requests
export const makeAuthenticatedRequest = async (url, options = {}) => {
  const { refreshToken } = useAuth();
  await refreshToken();
  const auth = getAuth();
  const token = await auth.currentUser.getIdToken();
  const headers = {
    ...options.headers,
    'Authorization': `Bearer ${token}`
  };
  return fetch(url, { ...options, headers });
};